<template>
    <div class="chart" ref="chartdiv">
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
    name: 'Chart',
    props: ['chartReadings', 'type'],
    data() {
        return {}
    },
    methods: {
        getDirection(degrees){
            let direction = this.translations["N"];
            if(degrees >= 0 && degrees < 45){
                direction = this.translations["N"];
            }
            if(degrees >= 45 && degrees < 90){
                direction = this.translations["NE"];
            }
            if(degrees >= 90 && degrees < 135){
                direction = this.translations["E"]
            }
            if(degrees >= 135 && degrees < 180){
                direction = this.translations["SE"];
            }
            if(degrees >= 180 && degrees < 225){
                direction = this.translations["S"];
            }
            if(degrees >= 225 && degrees < 270){
                direction = this.translations["SW"];
            }
            if(degrees >= 270 && degrees < 315){
                direction = this.translations["W"];
            }
            if(degrees >= 315 && degrees < 360){
                direction = this.translations["NW"];
            }
            return direction;
        },
        createChart() {
            let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            chart.paddingRight = 20;

            let data = [];
            if(this.type === 'wind_direction'){
                this.chartReadings.forEach(sensorData => {
                    data.push({
                        date: new Date(sensorData.CREATED_AT),
                        name: 'name' + Math.random(),
                        value: sensorData[this.type],
                        label: this.getDirection(sensorData[this.type])
                    })
                });
            }else{
                this.chartReadings.forEach(sensorData => {
                    data.push({
                        date: new Date(sensorData.CREATED_AT),
                        name: 'name' + Math.random(),
                        value: sensorData[this.type]
                    })
                });
            }


            chart.data = data;

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 75;
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.baseInterval = {
                "timeUnit": "minute",
                "count": 1
            }

// this hides regular labels close to the start/end
            dateAxis.renderer.minLabelPosition = 0.15;
            dateAxis.renderer.maxLabelPosition = 0.85;

// add ranges
            var minRange = dateAxis.axisRanges.create();
// this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            minRange.maxPosition = 1;
            minRange.minPosition = 0;
            minRange.label.horizontalCenter = "left"
            minRange.label.paddingLeft = 0;


            var maxRange = dateAxis.axisRanges.create();
// this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            maxRange.maxPosition = 1;
            maxRange.minPosition = 0;
            maxRange.label.horizontalCenter = "right"
            maxRange.label.paddingRight = 0;

            dateAxis.events.on("startendchanged", updateRangeLabels)
            dateAxis.events.on("extremeschanged", updateRangeLabels)

            function updateRangeLabels() {
                minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
                minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "HH:mm");

                maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
                maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "HH:mm");
            }

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.minWidth = 35;

            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            series.dataFields.valueY = "value";


            if (this.type === 'wind_direction') {
                valueAxis.renderer.labels.template.disabled = true;
                var range = valueAxis.axisRanges.create();
                range.value = 0;
                range.label.inside = false;
                range.label.text = this.translations["N"];


                range = valueAxis.axisRanges.create();
                range.value = 45;
                range.label.inside = false;
                range.label.text = this.translations["NE"];

                range = valueAxis.axisRanges.create();
                range.value = 90;
                range.label.inside = false;
                range.label.text = this.translations["E"];

                range = valueAxis.axisRanges.create();
                range.value = 135;
                range.label.inside = false;
                range.label.text = this.translations["SE"];

                range = valueAxis.axisRanges.create();
                range.value = 180;
                range.label.inside = false;
                range.label.text = this.translations["S"];

                range = valueAxis.axisRanges.create();
                range.value = 225;
                range.label.inside = false;
                range.label.text = this.translations["SW"];

                range = valueAxis.axisRanges.create();
                range.value = 270;
                range.label.inside = false;
                range.label.text = this.translations["W"];

                range = valueAxis.axisRanges.create();
                range.value = 315;
                range.label.inside = false;
                range.label.text = this.translations["NW"];

                range = valueAxis.axisRanges.create();
                range.value = 360;
                range.label.inside = false;
                range.label.text = this.translations["N"];

                valueAxis.min = 0;
                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;

                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0, 1100];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;

            }else if (this.type === 'heat_index') {

                var gradient = new am4core.LinearGradient();
                gradient.addColor(am4core.color("blue"));
                gradient.addColor(am4core.color("yellow"));
                gradient.addColor(am4core.color("orange"));
                gradient.addColor(am4core.color("red"));
                gradient.rotation = 270;
                series.fill = gradient;
                series.fillOpacity = 0.75;

            }else if (this.type === 'wind_chill') {

                var gradient = new am4core.LinearGradient();
                gradient.addColor(am4core.color("blue"));
                gradient.addColor(am4core.color("yellow"));
                gradient.addColor(am4core.color("orange"));
                gradient.addColor(am4core.color("red"));
                gradient.rotation = 270;
                series.fill = gradient;
                series.fillOpacity = 0.75;

            }else if (this.type === 'dew_point') {

                var gradient = new am4core.LinearGradient();
                gradient.addColor(am4core.color("blue"));
                gradient.addColor(am4core.color("yellow"));
                gradient.addColor(am4core.color("orange"));
                gradient.addColor(am4core.color("red"));
                gradient.rotation = 270;
                series.fill = gradient;
                series.fillOpacity = 0.75;

            }else if (this.type === 'temperature') {

                var gradient = new am4core.LinearGradient();
                gradient.addColor(am4core.color("blue"));
                gradient.addColor(am4core.color("yellow"));
                gradient.addColor(am4core.color("orange"));
                gradient.addColor(am4core.color("red"));
                gradient.rotation = 270;
                series.fill = gradient;
                series.fillOpacity = 0.75;

            } else if (this.type === 'light_intensity') {
                series.fill = am4core.color("#d9be1e"); // fill
                series.fillOpacity = 1;

                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0, 1];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;

            } else if (this.type === 'pressure') {
                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;
                // valueAxis.min = 1000;
                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0,  1];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;
            }else if (this.type === 'relative_pressure') {
                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;
                // valueAxis.min = 1000;
                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0,  1];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;
            } else {
                valueAxis.min = 0;
                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;

                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0, 1100];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;
            }


            let bullet = series.bullets.push(new am4charts.Bullet());
            let square = bullet.createChild(am4core.Rectangle);
            square.width = 3;
            square.height = 3;


            if(this.type === 'wind_direction'){
                // series.dataFields.valueY = "label";
                series.tooltipText = "{label}";
            }else{
                series.tooltipText = "{valueY.value}";
            }
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("white");
            series.tooltip.label.fill = am4core.color('#05969a');
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "none";

            this.chart = chart;
        }
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    mounted() {
        this.translations = window.translations
        this.createChart()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
    margin-top: 10px;
}
@media all and (max-width: 800px) {
    .chart {
        width: 100%;
        height: 250px;
        margin-top: 10px;
    }
}
</style>
