<template>
    <div class="modal fade" :id="'editNode_' + node.imei" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Node Edit</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form method="POST">
                <div class="modal-body">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="active" id="active">
                        <label class="form-check-label" for="active">
                            Active
                        </label>
                    </div>
                    <input id="name" type="text" class="form-control" name="fname" placeholder="Name" v-model="nodeName">
                    <input id="imei" type="text" class="form-control" name="fname" placeholder="imei" :value="node.imei"  disabled>
                    <input id="lat" type="text" class="form-control" name="fname" placeholder="lat"  v-model="lat">
                    <input id="lang" type="text" class="form-control" name="fname" placeholder="lng"  v-model="lng">


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="editNode">Save changes</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    props: [ 'node' ],
    data() {
        return {
            nodeName: this.node.name,
            lat: this.node.lat,
            lng: this.node.lng,
            active: this.node.active
        }
    },
    methods: {
        editNode() {
            this.axios
                .post('/node-update',
                { imei : this.node.imei,
                        name : this.nodeName,
                        lat : this.lat,
                        lng : this.lng,
                        active : this.active,

                     })
                .then(response => {
                    this.close()
                })
        },
        close:function (){
            $('#editNode_'+this.node.imei).modal('hide');
            bus.$emit('modal_closed',true)
        },
    }
}
</script>
