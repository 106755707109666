<template>
    <div class="container">
        <div class="row" style="margin-top:10px;margin-bottom: 5px;">
            <div class="col-4">
                <button type="button" @click='open_create_node_form' id="btn_open_form" class="btn btn-primary">New Node</button>
            </div>
            <div class="col-4">
                <button type="button" @click='save_node()' id='btn_save_node' class="btn btn-success d-none">Save Node</button>
            </div>
            <div class="col-4">
                <button type="button" @click='close_create_node_form()' id='btn_cancel' class="btn btn-danger d-none">Cancel</button>
            </div>
        </div>
        <div class="row d-none" id="form_new_node" style="border: 1px solid black;margin-top:10px; margin-bottom: 10px;">
            <div class="col-12">

                    <div class="form-group">
                        <label for="create_node_name">Name</label>
                        <input type="text" class="form-control" id="create_node_name" placeholder="Name">
                    </div>
                    <div class="form-group">
                        <label for="create_node_imei">IMEI</label>
                        <input type="text" class="form-control" id="create_node_imei" placeholder="IMEI">
                    </div>
                    <div class="form-group">
                        <label for="create_node_lat">Lat</label>
                        <input type="text" class="form-control" id="create_node_lat" placeholder="Lat">
                    </div>
                    <div class="form-group">
                        <label for="create_node_lng">Lng</label>
                        <input type="text" class="form-control" id="create_node_lng" placeholder="Lng">
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="create_node_active">
                        <label class="form-check-label" for="create_node_active">Active</label>
                    </div>

            </div>
        </div>
        <div class="row">
            <div class="table-responsive table-bordered movie-table">

                <table class="table" style="margin-bottom: 100px;">
                    <thead>
                    <tr class= "">
                        <th>Name</th>
                        <th>Imei</th>
                        <th>Lat/Lng</th>
                        <th>###</th>
                        <th>###</th>
                    </tr>
                    </thead>
                    <tbody>

                    <!--row-->
                    <tr class="dark-row" :class="{ 'inactive-row':!node.active }" v-for="node in nodes">
                        <td>{{ node.name || "Null" }}</td>
                        <td> {{ node.imei }} </td>
                        <td>{{ node.lat }} / {{ node.lng }}</td>
                        <td><a data-toggle="modal" :data-target="'#editNode_' + node.imei">Edit</a></td>
                        <td><button type="button" @click="deleteNode(node.imei)" class="btn btn-danger">Delete</button></td>
                    </tr>
                    <!--/.row-->



                    </tbody>
                </table>
            </div>
        </div>
        <edit-node-modal v-for="node in nodes" :key="node.imei" :node="node" :ref="'editNode_' + node.imei"></edit-node-modal>

<!--        <sidebar v-for="node in nodes" :key="node.imei" :node="node" :ref="'sidebar_'+ node.imei">-->
<!--        </sidebar>-->
    </div>
</template>

<script>
import {bus} from "../app";

export default {
    data: function () {
        return {
            nodes: []
        }
    },
    mounted() {
        this.getData();
    },
    created() {
        bus.$on('modal_closed', () => {
            this.getData();
        });
    },
    methods:{
        getData()
        {
            this.axios
                .get('/nodes?map=0')
                .then(response => {
                    this.nodes = response.data;
                    console.log(this.nodes);
                })
        },
        deleteNode(node_id)
        {
            if(confirm("You are about to delete "+node_id+", Are you sure?")){

                this.axios
                    .delete('/node/'+node_id)
                    .then(response => {
                        this.getData();
                    })
            }
        },
        open_create_node_form() {
            $('#btn_open_form').addClass('d-none')
            $('#btn_save_node').removeClass('d-none')
            $('#btn_cancel').removeClass('d-none')
            $('#form_new_node').removeClass('d-none')
        },
        save_node(){
            let self = this;
            let name = $('#create_node_name').val();
            let imei = $('#create_node_imei').val();
            let lat = $('#create_node_lat').val();
            let lng = $('#create_node_lng').val();
            let active = $('#create_node_active').is(':checked');
            if(name.length <= 0 || imei.length <= 0 || lat.length <= 0 || lng.length <= 0){
                alert("Fill all blanks");
                return;
            }
            this.axios
                .post('/node-create',
                    {
                        name : name,
                        imei : imei,
                        lat : lat,
                        lng : lng,
                        active : active,
                    })
                .then(response => {
                    self.init_create_field();
                    self.close_create_node_form();
                    self.getData()
                }).catch(err => {
                    alert(err.response.data.msg)
                });
        },
        close_create_node_form(){
            $('#btn_open_form').removeClass('d-none')
            $('#btn_save_node').addClass('d-none')
            $('#btn_cancel').addClass('d-none')
            $('#form_new_node').addClass('d-none')
        },
        init_create_field(){
            $('#create_node_name').val('');
            $('#create_node_imei').val('');
            $('#create_node_lat').val('');
            $('#create_node_lng').val('');
            $('#create_node_active').val(false)
        }
    }
}
</script>
<style scoped>
.active-row {
    background-color: #2fa360;
    font-weight: bold;
}
.inactive-row {
    background-color: #FFB2B2;
    font-weight: bold;
    color: white;
}

</style>
