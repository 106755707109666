<template>
    <div style="width:100%; height: 100vh;">
        <sidebar v-for="node in nodes" :key="node.imei" :node="node" :ref="'sidebar_'+ node.imei">
        </sidebar>

        <div class="map">
            <l-map id="stations-map"
                   :options="{zoomControl:false}"
                   :zoom="currentZoom"
                   :center="currentCenter">

                <l-marker v-for="node in nodes" :key="node.id"
                    :lat-lng="[node.lat, node.lng]"
                    :icon="icon"
                    @click="openSidebar(node)">
                </l-marker>

                <l-tile-layer :url="url"></l-tile-layer>
                <l-control-zoom position="topleft"></l-control-zoom>

            </l-map>
        </div>
    </div>
</template>

<script>
import markerImage from "../../../public/images/location.svg"
import L, {latLng} from 'leaflet';
import {LMap, LTileLayer, LMarker, LControlZoom} from 'vue2-leaflet';

function showModal(imei) {
    $('#' + imei).modal();
}

export default {
    name: 'TheMap',
    data: function () {
        return {
            center: L.latLng(38.70305624916754, 23.717504267390936),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?apikey=e955b6c9bc0b4ec6a38495e3f90d87b5',
            currentCenter: latLng(39.367785741790094, 21.91917886986617),
            currentZoom: 11,
            markerImage: markerImage,
            icon: L.icon({
                iconUrl: markerImage,
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            nodes: [],
            offIcon: null,
            keyword: '',
            myPagedMarkers: [],
            myMarkers: [],
            firstFillDone: false
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlZoom
    },
    computed: {
        dynamicSize () {
            return [this.iconSize, this.iconSize * 1.15];
        },
        dynamicAnchor () {
            return [this.iconSize / 2, this.iconSize * 1.15];
        }
    },
    beforeCreate() {
        this.axios
            .get('/nodes?map=1')
            .then(response => {
                this.nodes = response.data;
            })
    },
    methods: {
        openSidebar(node) {
            this.$refs['sidebar_' + node.imei][0].loadNodeData();
            $('#sidebar_' + node.imei).modal();
        },

    },
};
</script>

<style scoped>
.map {
    height: 100vh;
    width: 100%;
    position:relative;
    z-index: 1;
}
</style>
