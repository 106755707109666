<template>
    <!-- HTML -->
    <div id="chartdiv"></div>
</template>

<!-- Styles -->
<style>
#chartdiv {
    width: 100%;
    height: 350px;
}
@media all and (max-width: 800px) {
    #chartdiv {
        width: 100%;
        height: 250px;
    }
}
</style>

<script>

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.options.autoDispose = true;

export default {
    name: 'Compass',
    props: [ 'direction' ],
    data() {
        return {
            translations: [],
        }
    },
    methods: {
        createCompass() {
        // Themes begin
            am4core.useTheme(am4themes_animated);
        // Themes end

        // create chart
            var chart = am4core.create("chartdiv", am4charts.GaugeChart);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

            chart.startAngle = -90;
            chart.endAngle = 270;

            var axis = chart.xAxes.push(new am4charts.ValueAxis());
            axis.min = 0;
            axis.max = 360;
            axis.strictMinMax = true;

            axis.renderer.line.strokeWidth = 8;
            axis.renderer.line.strokeOpacity = 1;
            axis.renderer.line.stroke = am4core.color("#999");
            axis.renderer.inside = true;

            axis.renderer.axisFills.template.disabled = true;
            axis.renderer.grid.template.disabled = true;
            axis.renderer.ticks.template.disabled = false
            axis.renderer.ticks.template.length = 12;
            axis.renderer.ticks.template.strokeOpacity = 1;

            axis.renderer.labels.template.radius = 35;
            axis.renderer.labels.template.disabled = true;
            axis.renderer.ticks.template.disabled = true;

            function createLabel(label, deg) {
                var range = axis.axisRanges.create();
                range.value = deg;
                range.grid.disabled = true;
                range.label.text = label;
            }

            createLabel(this.translations["N"], 0);
            createLabel("", 22.5);
            createLabel(this.translations["NE"], 45);
            createLabel("", 67.5);
            createLabel(this.translations["E"], 90);
            createLabel("", 112.5);
            createLabel(this.translations["SE"], 135);
            createLabel("", 157.5);
            createLabel(this.translations["S"], 180);
            createLabel("", 202.5);
            createLabel(this.translations["SW"], 225);
            createLabel("", 247.5);
            createLabel(this.translations["W"], 270);
            createLabel("", 292.5);
            createLabel(this.translations["NW"], 315);
            createLabel("", 337.5);


        // hands
            var northHand = chart.hands.push(new am4charts.ClockHand());
            northHand.radius = am4core.percent(60);
            northHand.startWidth = 20;
            northHand.endWidth = 1;
            northHand.rotationDirection = "clockWise";
            northHand.pin.disabled = true;
            northHand.zIndex = 0;
            northHand.fill = am4core.color("#c00");
            northHand.stroke = am4core.color("#c00");
            northHand.value = this.direction;

            var southHand = chart.hands.push(new am4charts.ClockHand());
            southHand.radius = am4core.percent(60);
            southHand.startWidth = 20;
            southHand.endWidth = 1;
            southHand.rotationDirection = "clockWise";
            southHand.pin.disabled = true;
            southHand.zIndex = 0;
            southHand.fill = am4core.color("#555");
            southHand.stroke = am4core.color("#555");
            southHand.value = (this.direction + 180)%360;

        }
    },
    mounted() {
        this.translations = window.translations
        this.createCompass()
    }
}


</script>

